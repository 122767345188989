import { useRef, useState } from 'react';
import { Box, Fade, Modal, useTheme } from '@mui/material';

import {
  Button,
  elevations,
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CONFIGURATION_TYPE_OPTIONS } from '../constants';
import AiConfigurationSection from './AiQaConfigurationSection';
import AiConfigurationTest from './AiQonfigurationTest';

interface AiQaConfigurationViewProps {
  onClose: () => void;
  open: boolean;
}

const AiQaConfigurationView = ({
  onClose,
  open,
}: AiQaConfigurationViewProps) => {
  const { palette } = useTheme();

  const [configTypeIndex, setConfigTypeIndex] = useState(0);
  const configTypeValue = CONFIGURATION_TYPE_OPTIONS[configTypeIndex];

  const capitalizedTabs = CONFIGURATION_TYPE_OPTIONS.map(
    option => option.charAt(0).toUpperCase() + option.slice(1),
  );

  // used to determine if the modal should fade in or out (only fade it when coming from another page)
  const prevOpen = useRef(open);

  const handleClose = () => {
    prevOpen.current = false;
    onClose();
  };

  return (
    <Modal
      aria-labelledby='autopilot-policy-modal'
      closeAfterTransition
      onClose={handleClose}
      open={open}
    >
      <Fade appear={!prevOpen.current} in={open}>
        <Box
          sx={{
            backgroundColor: palette.colors.slate[100],
            height: '100vh',
            padding: '24px',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: palette.colors.white,
              borderRadius: '12px',
              boxShadow: elevations.z2,
              display: 'flex',
              justifyContent: 'space-between',
              padding: '18px 24px',
            }}
          >
            <Typography variant='font18Bold'>AI QA Configuration</Typography>
            <Button onClick={handleClose} variant='secondary'>
              Close
            </Button>
          </Box>
          <Box paddingTop='10px'>
            <Tabs
              a11yProps={index => ({
                'aria-controls': `tabpanel-${index}`,
                id: `tab-${index}`,
              })}
              aria-label='tabs to pick config type table below'
              onChange={(_, tab) => {
                setConfigTypeIndex(tab);
              }}
              tabs={capitalizedTabs}
              typographyVariant='font18'
              value={configTypeIndex}
            />
          </Box>
          <Box display='flex' flexDirection='row' gap={3}>
            <Box display='flex' flex={2}>
              <AiConfigurationSection configType={configTypeValue} />
            </Box>
            <Box display='flex' flex={1}>
              <AiConfigurationTest title='Test agent responses' />
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AiQaConfigurationView;
