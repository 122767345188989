import { Formik, useField } from 'formik';
import {
  Box,
  StandardTextFieldProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getAiScoreColor } from '../utils';

interface AiConfigurationTestProps {
  title: string;
}

const AiConfigurationTest = ({ title }: AiConfigurationTestProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: palette.colors.white,
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        minHeight: 'calc(100vh - 225px)',
        padding: '24px',
        width: '100%',
      }}
    >
      <Box display='flex'>
        <Typography variant='font20'>{title}</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='16px'>
        <Typography variant='font14Bold'>
          Test all created AI QA criteria with your own text
        </Typography>
        <Formik initialValues={{ text: '' }} onSubmit={({}) => undefined}>
          {({ handleSubmit, values }) => {
            return (
              <>
                <FormikTextField name='text' />
                <Button
                  disabled={values.text.trim() === ''}
                  onClick={() => {
                    handleSubmit();
                  }}
                  type='submit'
                  variant='secondary'
                >
                  Test
                </Button>
              </>
            );
          }}
        </Formik>
      </Box>
      <PredictionScoring />
    </Box>
  );
};

interface FormikInputProps extends Omit<StandardTextFieldProps, 'label'> {
  name: string;
}

const FormikTextField = ({ name, ...props }: FormikInputProps) => {
  const [field, , { setValue }] = useField({ name });

  return (
    <TextField
      aria-label='demo-widget'
      multiline
      onChange={e => {
        setValue(e.target.value);
      }}
      rows={5}
      value={field.value}
      {...props}
    />
  );
};

const PredictionScoring = () => {
  const data = [
    {
      label: 'Example Label 1',
      reasoning: 'Reasoning for prediction 1',
      score: '85',
    },
    {
      label: 'Example Label 2',
      reasoning: 'Reasoning for prediction 2',
      score: '50',
    },
    {
      label: 'Example Label 3',
      reasoning: 'Reasoning for prediction 3',
      score: '25',
    },
  ];

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='font14Medium'>Label</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='font14Medium'>Score</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='font14Medium'>
                Prediction reasoning
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            const score = parseInt(row.score, 10);
            return (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant='font14'>{row.label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color={getAiScoreColor(score)}
                    variant='font14Bold'
                  >
                    {row.score}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='font14'>{row.reasoning}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default AiConfigurationTest;
