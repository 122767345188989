import {
  IconArrowDownToArc,
  IconMicrophone,
  IconMoodSmileBeam,
  IconVocabulary,
  TablerIconsProps,
} from '@tabler/icons-react';
import { IconBulb } from '@tabler/icons-react';

import { PlaybookTabKeys } from './types';
import { bubbleAvatars } from 'src/components/dashboard-pages/integrations-page/integrations-page-header/utils';

export const PLAYBOOK_TABS: {
  Icon: (props: TablerIconsProps) => JSX.Element;
  name: string;
  tab: PlaybookTabKeys;
}[] = [
  {
    Icon: IconBulb,
    name: 'Knowledge',
    tab: 'knowledge',
  },
  {
    Icon: IconVocabulary,
    name: 'Instruction',
    tab: 'instructional-note',
  },
  {
    Icon: IconArrowDownToArc,
    name: 'Fallback',
    tab: 'fallback',
  },
  {
    Icon: IconMicrophone,
    name: 'Tone',
    tab: 'tone-of-voice',
  },
  {
    Icon: IconMoodSmileBeam,
    name: 'Feedback',
    tab: 'quick-feedback',
  },
];

export const TONE_PREVIEW_TEXT =
  'Express your brand through tone of voice, establishing consistency to strengthen brand identity and foster connections with customers.';

export const FEEDBACK_PREVIEW_TEXT = `Personalize your quick feedback using the menu on the right. 
Allow your agents to provide feedback on sources used for generated replies or AI chat responses, such as articles and workflows`;

export const INSTRUCTIONAL_NOTE_PREVIEW_TEXT = `Inform the AI so it can provide more relevant, accurate, and context-aware assistance to your agents.

Helps the AI focus on important needs like troubleshooting, tickets, product info, and account support.

Keeps the AI aware of outdated or unverified data.

Stops the AI from suggesting things like "contact support team" when the agent is already helping.

What should the AI understand about your knowledge base when interacting with your agents?
`;

export const FALLBACK_PREVIEW_TEXT = `The “Fallback” workflow activates when Knowledge Retrieval can't find relevant content for an agent's inquiry, allowing you to define the chatbot's response.

To prevent irrelevant suggestions, the Articles feature is disabled during fallback scenarios`;

export const COMPANY_AVATARS = {
  confluence: bubbleAvatars.right[1],
  intercom: bubbleAvatars.left[3],
  readmeio: bubbleAvatars.right[3],
  salesforce: bubbleAvatars.left[0],
  snowflake: bubbleAvatars.left[1],
  zendesk: bubbleAvatars.right[2],
};
