import { useState } from 'react';
import { Box } from '@mui/material';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';

import {
  Button,
  Dialog,
  TextField,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { AiQaConfig, AiQaConfigType } from 'src/services/insights/types';

interface AiQaConfigurationDialogProps {
  configType: AiQaConfigType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createConfigMutation: MutationTrigger<any>;
  isConfigModalOpen: boolean;
  isLoading: boolean;
  selectedConfig: AiQaConfig | null;
  setIsConfigModalOpen: (isOpen: boolean) => void;
  setSelectedConfigId: (id: string | null) => void;
  setUiModelNameBeingChanged: (modelName: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateConfigMutation: MutationTrigger<any>;
}

const AiQaConfigurationDialog = ({
  configType,
  createConfigMutation,
  isConfigModalOpen,
  isLoading,
  selectedConfig,
  setIsConfigModalOpen,
  setSelectedConfigId,
  setUiModelNameBeingChanged,
  updateConfigMutation,
}: AiQaConfigurationDialogProps) => {
  const [uiConfig, setUiConfig] = useState<AiQaConfig>(
    selectedConfig ?? ({} as AiQaConfig),
  );
  const isNewConfig = selectedConfig === null;

  const {
    is_active = false,
    model_definition = '',
    model_name = '',
    model_scoring_definition = '',
  } = uiConfig || {};

  const handleCancelOnClick = () => {
    setIsConfigModalOpen(false);
    setSelectedConfigId(null);
  };

  const isUpdateOrCreateDisabled =
    isLoading || !model_definition || !model_name || !model_scoring_definition;

  const handleOnCreateClick = async () => {
    try {
      await createConfigMutation({
        config_type: configType,
        is_active,
        model_definition,
        model_name,
        model_scoring_definition,
      }).unwrap();
      setIsConfigModalOpen(false);
      setUiModelNameBeingChanged(model_name);
      setSelectedConfigId(null);
    } catch (err) {
      console.error('Failed to create configuration', err);
    }
  };

  const handleOnUpdateClick = async () => {
    try {
      await updateConfigMutation({
        config_type: configType,
        is_active,
        model_definition,
        model_id: selectedConfig?.model_id,
        model_name,
        model_scoring_definition,
      }).unwrap();
      setIsConfigModalOpen(false);
      setUiModelNameBeingChanged(model_name);
      setSelectedConfigId(null);
    } catch (err) {
      console.error('Failed to create configuration', err);
    }
  };

  return (
    <Dialog
      footer={
        <>
          <Button onClick={handleCancelOnClick} variant='ghost'>
            Cancel
          </Button>
          {isNewConfig ? (
            <Button
              disabled={isUpdateOrCreateDisabled}
              isLoading={isLoading}
              onClick={handleOnCreateClick}
              variant='secondary'
            >
              Create
            </Button>
          ) : (
            <Button
              disabled={isUpdateOrCreateDisabled}
              isLoading={isLoading}
              onClick={handleOnUpdateClick}
              variant='secondary'
            >
              Save
            </Button>
          )}
        </>
      }
      hideCloseButton
      open={isConfigModalOpen}
      title='AI QA metric'
    >
      <Box display='flex' flexDirection='column' gap={3} width='550px'>
        <Box alignItems='center' display='flex' gap='12px'>
          <Toggle
            checked={is_active ?? false}
            onChange={() => {
              setUiConfig({ ...uiConfig, is_active: !is_active });
            }}
          />
          <Typography variant='font14'>Metric enabled</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Typography variant='font14Bold'>Name</Typography>
          <TextField
            aria-label='Metric name'
            onChange={e => {
              const { value } = e.target;
              setUiConfig({ ...uiConfig, model_name: value });
            }}
            placeholder='Enter metric name. E.g. Empty'
            value={model_name}
          />
        </Box>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Typography variant='font14Bold'>Metric definition</Typography>
          <Typography variant='font14'>
            Input the AI QA criteria to enable the AI model to assess your
            agents&apos; performance.
          </Typography>
          <TextField
            aria-label='Metric definition'
            multiline
            onChange={e => {
              const { value } = e.target;
              setUiConfig({ ...uiConfig, model_definition: value });
            }}
            placeholder='Provide structure metric rules. For example: Measure empathy by assessing factors such as tone, language, acknowledgment of customer concerns, expressions of understanding, and the use of supportive or reassuring phrases. Identify patterns in empathetic responses and compare them with neutral or non-empathetic replies. Provide a quantitative empathy score based on linguistic markers and sentiment analysis, along with qualitative insights on areas for improvement.'
            rows={7}
            value={model_definition}
          />
        </Box>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Typography variant='font14Bold'>Scoring definition</Typography>
          <TextField
            aria-label='Scoring definition'
            multiline
            onChange={e => {
              const { value } = e.target;
              setUiConfig({ ...uiConfig, model_scoring_definition: value });
            }}
            placeholder='Provide structure scoring  rules. For example: A score of 100 indicates that all applicable criteria have been met in the response. Deduct 20 from 100 if any applicable criterion is not met in the response.'
            rows={3}
            value={model_scoring_definition}
          />
        </Box>
        <Box display='flex' flexDirection='column' gap='12px'>
          <Typography variant='font14'>
            The new metric will take effect only for future tickets.
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AiQaConfigurationDialog;
