import { useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from '@mui/material';
import { IconArrowsUpDown } from '@tabler/icons-react';

import {
  Alert,
  Button,
  FilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { AI_CONFIG_SORTING_OPTIONS } from '../constants';
import AiQaConfigurationCard from './AiQaConfigurationCard';
import AiQaConfigurationDialog from './AiQaConfigurationDialog';
import { AlertModalContainer } from 'src/pages/workflow-builder/landing-page/LandingPage';
import {
  useCreateAiQaConfigMutation,
  useDeleteAiQaConfigMutation,
  useGetAiQaConfigsQuery,
  useUpdateAiQaConfigMutation,
} from 'src/services/insights';
import { AiQaConfigType } from 'src/services/insights/types';

interface AiConfigurationSectionProps {
  configType: AiQaConfigType;
}

const AiConfigurationSection = ({
  configType,
}: AiConfigurationSectionProps) => {
  const { palette } = useTheme();
  const [sortBy, setSortBy] = useState('last_updated_at');
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [selectedConfigId, setSelectedConfigId] = useState<string | null>(null);
  const [uiModelNameBeingChanged, setUiModelNameBeingChanged] = useState('');

  // Fetch
  const { data } = useGetAiQaConfigsQuery({
    config_type: configType,
    sort_by: sortBy,
  });
  // Create
  const [
    createConfigMutation,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreate },
  ] = useCreateAiQaConfigMutation({ fixedCacheKey: 'createAiQaConfig' });
  // Update
  const [
    updateConfigMutation,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate },
  ] = useUpdateAiQaConfigMutation({ fixedCacheKey: 'updateAiQaConfig' });
  // Delete
  const [
    deleteConfigMutation,
    { isLoading: isLoadingDelete, isSuccess: isSuccessDelete },
  ] = useDeleteAiQaConfigMutation({ fixedCacheKey: 'deleteAiQaConfig' });

  const selectedConfig = useMemo(
    () =>
      data?.data.find(config => config.model_id === selectedConfigId) || null,
    [data, selectedConfigId],
  );

  const isEditOnClick = (id: string) => {
    setSelectedConfigId(id);
    setIsConfigModalOpen(true);
  };

  const handleOnDeleteClick = async (selectedConfigId: string | null) => {
    if (selectedConfigId) {
      try {
        await deleteConfigMutation({
          model_id: selectedConfigId,
        }).unwrap();
        setIsConfigModalOpen(false);
        setSelectedConfigId(null);
      } catch (err) {
        console.error('Failed to delete configuration', err);
      }
    }
  };

  const renderAlert = (isSuccess: boolean, message: string) => {
    return (
      isSuccess &&
      uiModelNameBeingChanged && (
        <AlertModalContainer>
          <Alert
            onClose={() => setUiModelNameBeingChanged('')}
            title={message}
            variant='main'
          />
        </AlertModalContainer>
      )
    );
  };

  return (
    <>
      <Box display='flex' flexDirection='column' gap={2} paddingTop='32px'>
        <Typography variant='font16'>
          This function enables organizations to set AI-driven QA criteria for
          assessing agent responses in support tickets. By defining key
          performance indicators, the AI analyzes interactions to provide
          insights into response quality, resolution efficiency, and customer
          satisfaction.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ backgroundColor: `${palette.colors.white} !important` }}>
            <FilterButton
              aria-label='sort by filter'
              initialValue='last_updated_at'
              onChange={value => setSortBy(value)}
              options={AI_CONFIG_SORTING_OPTIONS}
              startAdornment={
                <Box
                  sx={{ paddingRight: '4px', paddingTop: '4px', width: '24px' }}
                >
                  <IconArrowsUpDown
                    color={palette.colors.grey[700]}
                    width='18px'
                  />
                </Box>
              }
              value={sortBy}
            />
          </Box>
          <Button
            onClick={() => setIsConfigModalOpen(true)}
            startIcon={<AddIcon />}
            variant='secondary'
          >
            Create new
          </Button>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: '1fr',
            maxHeight: 'calc(100vh - 325px)',
            overflowY: 'auto',
          }}
        >
          {data?.data.map((config, idx) => {
            return (
              <AiQaConfigurationCard
                data={config}
                isLoadingDelete={isLoadingDelete}
                isLoadingUpdate={isLoadingUpdate}
                key={idx}
                onDeleteOnClick={() => {
                  handleOnDeleteClick(config.model_id);
                  setUiModelNameBeingChanged(config.model_name);
                }}
                onEditOnClick={() => isEditOnClick(config.model_id)}
                updateConfigMutation={updateConfigMutation}
              />
            );
          })}
        </Box>
      </Box>
      {isConfigModalOpen && (
        <AiQaConfigurationDialog
          configType={configType}
          createConfigMutation={createConfigMutation}
          isConfigModalOpen={isConfigModalOpen}
          isLoading={isLoadingCreate || isLoadingUpdate}
          selectedConfig={selectedConfig}
          setIsConfigModalOpen={setIsConfigModalOpen}
          setSelectedConfigId={setSelectedConfigId}
          setUiModelNameBeingChanged={setUiModelNameBeingChanged}
          updateConfigMutation={updateConfigMutation}
        />
      )}
      {/* Alert */}
      {renderAlert(
        isSuccessCreate,
        `The metric ‘${uiModelNameBeingChanged}’ has been created successfully!`,
      )}
      {renderAlert(
        isSuccessUpdate,
        `The metric ‘${uiModelNameBeingChanged}’ has been updated successfully!`,
      )}
      {renderAlert(
        isSuccessDelete,
        `The metric ‘${uiModelNameBeingChanged}’ has been deleted successfully!`,
      )}
    </>
  );
};

export default AiConfigurationSection;
