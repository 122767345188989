import { Box } from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  MultiSelectFilter,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetAgentOptionsFilter } from '../hook/useGetAgentOptionsFilter';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { DateRange } from 'src/types/types';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

interface HeaderProps {
  dateRange: DateRange;
  handleMultiSelectChange: (state: string[]) => void;
  localMultiSelected: string[];
  setDateRange: (dateRange: DateRange) => URLSearchParams;
  setIsAiQaConfigViewOpen: (state: boolean) => void;
  title: string;
}

const Header = ({
  dateRange,
  handleMultiSelectChange,
  localMultiSelected,
  setDateRange,
  setIsAiQaConfigViewOpen,
  title,
}: HeaderProps) => {
  const { filters } = useGetAgentOptionsFilter();

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box display='flex' gap={2}>
        <Typography variant='font24'>{title}</Typography>
        <Box display='flex' gap={1}>
          <DateRangeFilterButton
            explicitLabel
            initialValue={last30DaysTimeRange}
            onChange={setDateRange}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <MultiSelectFilter
            icon={<img src={filterIcon} />}
            maxHeight={400}
            onChange={handleMultiSelectChange}
            options={filters}
            placeholder='Filter by'
            value={localMultiSelected}
            variant='secondary'
          />
        </Box>
      </Box>
      <Button onClick={() => setIsAiQaConfigViewOpen(true)} variant='secondary'>
        <Typography variant='font14Bold'> AI QA metrics</Typography>
      </Button>
    </Box>
  );
};

export default Header;
