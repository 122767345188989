export const formatSecondsToReadableHrMinTime = (seconds?: number): string => {
  if (!seconds || seconds <= 0) {
    return '0 min';
  }

  if (seconds < 60) {
    return `${seconds.toFixed(0)} sec`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours > 0 ? `${hours} hr ` : ''}${
    minutes > 0 ? `${minutes} min` : ''
  }`.trim();
};

export const getAiScoreColor = (score: number): string => {
  if (score < 30) {
    return 'red';
  }
  if (score > 50) {
    return 'green';
  }
  return 'black';
};
